import React from 'react';

const Navigation = () => {
  return (
    <ul className="nav nav--panel js-scrollspy-nav uk-flex uk-flex-wrap">
      <li><a href="#about_us">About Us</a></li>
      <li><a href="#music">Music</a></li>
      <li><a href="#shows">Shows</a></li>     
       <li><a href="#references">References</a></li>
      <li><a href="#contact">Contact</a></li>
    </ul>
  );
};

export default Navigation;
