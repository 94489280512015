import React, { useState } from "react";

const referencesData = [
  {
    id: "michele-bolton",
    coverImage: "img/team/covers/alley.jpg",
    // avatarImage: "img/team/avatars/michele.jpg",
    name: "Michèle Bolton",
    // title: "OpenStudio Art Cafe Owner",
    quote: `“This recently formed band do not cease to amaze on how solid
      their sound has become in the short time they’ve played together. A true
      testament to their individual talents and commitment to a unique sound.
      A repertoire that hints ’60 psychedelia through to early 2000’s groove
      and the many waves between, they manage to make each sound their own.
      Paul and Mary lead with energized originals and covers, together with
      outstanding bandmates, Dennis, Jeff, and James providing solid sound and
      stage presence. It’s no wonder they’re filling venues across Durham.”`,
  },
  {
    id: "diane-godlin",
    coverImage: "img/team/covers/alley.jpg",
    avatarImage: "",
    name: "Diane Godlin",
    title: "",
    quote: `“I highly recommend Back Alley Prophet;
     they are outstanding as individual musicians and collectively. Honourary BAP member, and Guest harmonica player,
      Sara Campbell, is brilliant and brought the songs to a new level - mind blowing! This band gets better every time I see them!" ”`,
  },
  {
    id: "jane-betteridge",
    coverImage: "img/team/covers/alley.jpg",
    avatarImage: "",
    name: "Jane Betteridge",
    title: "",
    quote: `“Back Alley Prophet is an incredible band,
     that has been entertaining us in Durham for a number of years. Although their fanbase is 35+,
      there are numerous young people who join in the fun in dancing,
      listening, and singing along with this dynamic band..
       It is often that I bring different people to listen to them, and they always walk away extremely impressed by the talent they have. 
       Some of the songs that are sung are originals. The band has endless energy and enjoy interacting with the crowds whether on stage or during a break.
       You will have no regrets in joining them in any venue if you have an opportunity to hire them, it is a no-brainer"`,
  },
];

const ReferenceItem = ({ coverImage, avatarImage, name, title, quote }) => {
  const [isAvatarVisible, setIsAvatarVisible] = useState(!!avatarImage);

  const handleImageError = () => {
    setIsAvatarVisible(false); // Hide avatar if it fails to load
  };

  return (
    <div className="reference-item">
      <div className="modal__content referencecontent">
        {isAvatarVisible && (
          <div className="modal__avatar">
            <img
              src={avatarImage}
              alt={`${name}'s avatar`}
              onError={handleImageError}
            />
          </div>
        )}

        {title && (
          <div className="modal__profile__subtitle uk-text-center">{title}</div>
        )}
        <p className="uk-text-left">
          <span className="quote">{quote}</span>
          <span className="author"> – {name}</span>
        </p>
      </div>
    </div>
  );
};

const References = () => (
  <section
    id="references"
    className="references uk-section uk-scrollspy-inview container--min uk-container"
  >
    <h1>References</h1>
    <div className="references-grid">
      {referencesData.map((ref) => (
        <div id={ref.id} key={ref.id}>
          <ReferenceItem
            coverImage={ref.coverImage}
            avatarImage={ref.avatarImage}
            name={ref.name}
            title={ref.title}
            quote={ref.quote}
          />
        </div>
      ))}{" "}
      <div className="reference-item">
        {" "}
        <div className="modal__content referencecontent">
          <br></br> Ask them about us
          <br></br>
          <br></br>
          <br></br>
          <ul className="reflist uk-text-left">
            <li>
              Michelle Peters, Owner of The OpenStudio Cafe, Nautical Village of
              Pickering 905-420-2233
            </li>
            <li>
              Tarnya, Booking Manager of Knights Corners, Pickering 437-241-8282
            </li>
            <li>
              Sara Campbell, Host of Open Mic at OpenStudio Cafe in Pickering
              647-298-9336
            </li>

            <li>Penny Walker, Rotary Club Pickering, 905-442-1626</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

export default References;
